<template>
    <div>
        <el-drawer ref="drawerRef" v-model="dialogBasketDrawer" title="سبد خرید شما" :before-close="handleClose"
            direction="ltr" modal-class="basket-drawer" size="310">
            <div class="demo-drawe-wrapper">
                <div class="demo-drawer__content">
                    <div class="basket-wrapper">
                        <singleBasketItem></singleBasketItem>
                    </div>
                    <div class="demo-drawer__footer">
                        <el-button class="btn-back" @click="dialogBasketDrawer = false">بستن</el-button>
                        <el-button v-if="$myState.savedBasketItems.length > 0" class="btn-submit" type="primary"
                            :loading="loading" @click="onDialogBasketClick">{{
            loading ? 'Submitting ...' : 'تکمیل خرید'
        }}</el-button>
                    </div>
                </div>
            </div>
        </el-drawer>

        <el-menu :default-active="activeIndex2" class="el-menu-demo my-header" mode="horizontal"
            background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" @select="handleSelect">
            <div class="basket-panel">
                <el-badge :value="$myState.countBasketItems" class="basket-badge">
                    <img src="@/assets/img/icons/basket.png" @click="dialogBasketDrawer = !dialogBasketDrawer" />
                </el-badge>

            </div>
            <div class="logo-panel">
                <img src="@/assets/logo.png" @click="gotoHome" />
            </div>
            <el-dropdown v-if="$myState.userIsLogged == false" class="user-panel">
                <el-button type="primary">
                    کاربر مهمان<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item @click="gotoLogin">ورود به حساب کاربری</el-dropdown-item>
                        <el-dropdown-item divided>راهنما</el-dropdown-item>
                        <!-- <el-dropdown-item>خروج</el-dropdown-item> -->
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <el-dropdown v-else-if="$myState.userIsLogged == true && $myState.userRole == 'admin'" class="user-panel">
                <el-button type="primary">
                    {{ $myState.userLoggedInfo }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item @click="gotoLogin">پنل ادمین</el-dropdown-item>
                        <el-dropdown-item @click="gotoProjects">پروژه ها</el-dropdown-item>
                        <el-dropdown-item @click="gotoNewsAdmin">اخبار</el-dropdown-item>
                        <el-dropdown-item @click="gotoContacts">پیغام ها</el-dropdown-item>
                        <el-dropdown-item divided>راهنما</el-dropdown-item>
                        <el-dropdown-item @click="logoutUser">خروج</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <el-dropdown v-else class="user-panel">
                <el-button type="primary">
                    {{ $myState.userLoggedInfo }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item @click="gotoUserPanel">پنل کاربری</el-dropdown-item>
                        <el-dropdown-item divided>راهنما</el-dropdown-item>
                        <el-dropdown-item @click="logoutUser">خروج</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <div class="main-menu">
                <div class="logo-panel">
                    <img src="@/assets/logo.png" @click="gotoHome" />
                </div>
                <el-menu-item index="4" @click="gotoHome">صفحه اصلی</el-menu-item>
                <el-sub-menu index="2" disabled>
                    <template #title>خدمات</template>
                    <el-menu-item index="2-1">سفارش سایت</el-menu-item>
                    <el-menu-item index="2-2">مشاوره آنلاین</el-menu-item>
                    <el-menu-item index="2-3">درخواست آنلاین</el-menu-item>
                    <el-sub-menu index="2-4">
                        <template #title>آموزش</template>
                        <el-menu-item index="2-4-1">برنامه نویسی</el-menu-item>
                        <el-menu-item index="2-4-2">طراحی سایت</el-menu-item>
                        <el-menu-item index="2-4-3">طراحی اپ</el-menu-item>
                        <el-menu-item index="2-4-3">دیتابیس</el-menu-item>
                        <el-menu-item index="2-4-3">گرافیک</el-menu-item>
                    </el-sub-menu>
                </el-sub-menu>
                <el-sub-menu index="3">
                    <template #title>اخبار</template>
                    <el-menu-item index="3-1" @click="gotoNews('Tech')">اخبار تکنولوژی</el-menu-item>
                    <el-menu-item index="3-2" @click="gotoNews('Prog')">اخبار حوزه برنامه نویسی</el-menu-item>
                    <el-menu-item index="3-3" @click="gotoNews('Web')">اخبار سایت</el-menu-item>
                </el-sub-menu>
                <el-menu-item index="1" @click="gotoAboutUs">درباره ما</el-menu-item>
                <el-menu-item index="1" @click="gotoContactUs">تماس با ما</el-menu-item>
                <el-menu-item index="1" @click="gotoArvin" style="direction: ltr;">0910 494 0052</el-menu-item>
                <!-- <el-menu-item index="3" disabled>Info</el-menu-item> -->

            </div>

        </el-menu>
    </div>
</template>

<script>
import singleBasketItem from '../Cards/singleBasketItem.vue';

export default {
    components: { singleBasketItem },
    data() {
        return {
            dialogBasketDrawer: false,
        }
    },
    methods: {
        gotoArvin(){
            this.$router.push({ path: '/AboutArvin' });
        },
        gotoContacts() {
            this.$router.push({ name: 'SavedContact' });
        },
        gotoNewsAdmin() {
            this.$router.push({ name: 'SavedNews' });
        },
        gotoNews(news_cat) {
            this.$router.push({ name: 'News', params: { Cat: news_cat } });
        },
        gotoUserPanel() {
            this.$router.push({ name: 'userPanel' });
        },
        gotoProjects() {
            this.$router.push({ name: 'SavedProjects' });
        },
        logoutUser() {
            this.$myState.userIsLogged = false;
            this.$myState.userLoggedInfo = null;
            this.$myState.userLoggedMobile = null;
            this.$myState.userLoggedEmail = null;
        },
        gotoAboutUs() {
            this.$router.push({ name: 'about' });
        },
        gotoContactUs() {
            this.$router.push({ name: 'ContactUs' });
        },
        gotoHome() {
            this.$router.push({ name: 'home' });
        },
        gotoLogin() {
            this.$router.push({ name: 'login' });
        },
        onDialogBasketClick() {
            this.dialogBasketDrawer = !this.dialogBasketDrawer;
            this.$router.push({ name: 'BasketView' });
        }
    }
}
</script>

<style lang="scss">

.el-drawer__body {
    flex: 1;
    padding: 3px !important;
    overflow: auto;
    width: 96% !important;
}

.demo-drawe-wrapper{
    width: 100%;
    // margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 10px;
}

.el-icon--right {
    margin-right: 10px !important;
}

.btn-submit {
    width: 70%;
}

.btn-back {
    width: 25%;
}

.basket-drawer {
    min-width: 300px !important;
}


.my-header {
    position: relative;
    width: 100%;
    border-radius: 2px 2px 5px 5px;


    .basket-wrapper {
        width: 100%;
    }

    .main-menu {
        display: flex;
        position: absolute;
        right: 30px;
        direction: rtl;
    }

    .basket-panel {
        width: 40px;
        margin-left: 10px;
        margin-right: 20px;
        cursor: pointer;

        .basket-badge {
            margin-top: 15px;

            transition: transform .3s ease-in-out;


        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            transition: transform .3s ease-in-out;

            &:hover {
                transform: scale(1.1) translateX(-5px);
            }
        }
    }

    .logo-panel {
        width: 50px;
        margin-left: 10px;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            transition: transform .3s ease-in-out;

            &:hover {
                transform: scale(1.1) rotate(25deg);
            }
        }
    }

    .user-panel {
        display: flex;
        align-items: center;
        margin-left: 10px;
        direction: rtl;
    }
}
</style>